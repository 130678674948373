import React from 'react';
import { Row, Col, Form } from 'antd';
import { FillmentSlider } from '@/components/ui/FillmentSlider';
import { PercentageInput } from './PercentageInput';
import { Text } from '@/components/Typography';

const { useFormInstance } = Form;

interface ReportNameProps {
	bodyPart: 'neck' | 'trunk' | 'left_upper_arm' | 'right_upper_arm' | 'left_lower_arm' | 'right_lower_arm';
	isRequired?: boolean;
	namePlace?: string;
}

const BODY_PARTS_DESCRIPTION = {
	trunk: 'Torso',
	neck: 'Cervical spine',
	left_lower_arm: 'Left elbow',
	right_lower_arm: 'Right elbow',
	left_upper_arm: 'Left shoulder',
	right_upper_arm: 'Right shoulder'
};

export function BodyPartGoal({ isRequired = false, namePlace = 'goals', bodyPart }: Readonly<ReportNameProps>) {
	const { setFieldValue, getFieldValue } = useFormInstance();
	const percentage: number = getFieldValue([namePlace, bodyPart]);

	const handlePercentageChange = (value: number) => {
		setFieldValue([namePlace, bodyPart], value);
	};

	return (
		<>
			<Col xs={24} xl={20}>
				<Row gutter={[5, 0]}>
					<Col xl={7} xs={6}>
						<Text>{BODY_PARTS_DESCRIPTION[bodyPart]}</Text>
					</Col>
					<Col xl={14} sm={16}>
						<FillmentSlider onChange={handlePercentageChange} defaultValue={percentage} />
					</Col>
					<Col span={2}>
						<Form.Item required={isRequired} name={[namePlace, bodyPart]} initialValue={percentage}>
							<PercentageInput />
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</>
	);
}
