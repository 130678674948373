import React from 'react';
import { Col, Grid, Row } from 'antd';

import { DIRECTION, StepsNavigation } from '@/components/ui/StepsNavigation';
import { Steps } from '@/components/ui/StepsNavigation/types';
import { GeneralInformations } from './Steps/Informations';
import { SelectFiles } from './Steps/SelectFiles';
import { Evaluation } from './Steps/Evaluation';
import { useRotationReportContext } from '../context';
import { Goals } from './Steps/Goals';
import { Header } from './Header';
import * as S from './styles';
import { Results } from './Steps/Results';

const steps: Steps[] = [
	{
		id: '0',
		name: 'general_informations',
		sequence: 0,
		description: '',
		component: <GeneralInformations />
	},
	{
		id: '1',
		name: 'video_selection',
		sequence: 1,
		description: '',
		component: <SelectFiles />
	},
	{
		id: '2',
		name: 'goals',
		sequence: 2,
		description: '',
		component: <Goals />
	},
	{
		id: '3',
		name: 'evaluation_of_the_rotation',
		sequence: 3,
		description: '',
		component: <Evaluation />
	},
	{
		id: '4',
		name: 'results',
		sequence: 4,
		description: '',
		component: <Results />
	}
];

const stepsName = {
	general_informations: 'General informations',
	video_selection: 'Video selection',
	goals: 'Goals',
	evaluation_of_the_rotation: 'Evaluation of the rotation',
	results: 'Results'
};

export function Report() {
	const { handleStepNavigationClick, currentStep, rotationReport } = useRotationReportContext();
	const breakpoints = Grid.useBreakpoint();

	return (
		<Row justify="center">
			<Col span={24} style={{ marginTop: '2rem' }}>
				<Row justify="start" gutter={[16, 0]}>
					<Col xs={24} xl={{ offset: 4, span: 20 }} style={{ marginBottom: '2rem', padding: 0 }}>
						<Row justify="start">
							<Col xs={24} xl={20}>
								<Header />
							</Col>
						</Row>
					</Col>
					<Col xs={24} xl={4} xxl={4}>
						<StepsNavigation
							steps={steps}
							stepsName={stepsName}
							currentStep={currentStep}
							isComplete={rotationReport?.is_completed ?? false}
							onStepNavigationClick={handleStepNavigationClick}
							direction={!breakpoints.xl ? DIRECTION.HORIZONTAL : DIRECTION.VERTICAL}
						/>
					</Col>
					<S.CustomFormContainer xs={24} xl={20} xxl={19} $isSmallScreen={`${breakpoints.lg}`}>
						{steps && steps[currentStep].component}
					</S.CustomFormContainer>
				</Row>
			</Col>
		</Row>
	);
}
