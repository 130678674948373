import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';

import { RotationReportResultResponse } from '@/hooks/useGetRotationReportResult';
import { DividerCustom } from '@/components/ui/DividerCustom';
import { Title } from '@/components/Typography';
import { InfoItem } from './InfoItem';

interface InformationProps {
	informations?: RotationReportResultResponse['informations'];
}

export function Information({ informations }: Readonly<InformationProps>) {
	if (!informations) {
		return null;
	}

	const { risk_range, report_name, company_name, analysis_date, evaluator_name, organization_name } = informations;

	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={2} style={{ color: '#262626', marginLeft: 5 }}>
					Information
				</Title>
			</Col>
			<Col span={22} style={{ padding: '0rem 1rem 2rem', marginTop: -10 }}>
				<Row>
					<Col xs={24} lg={11}>
						<InfoItem title="Company" value={organization_name} />
						<InfoItem title="Industrial site" value={company_name} />
						<InfoItem title="Evaluator" value={evaluator_name} marginBottom={0} />
					</Col>
					<Col xs={0} lg={1}>
						<Row style={{ height: '100%' }}>
							<DividerCustom span={24} color="#D9D9D9" type="vertical" thickness="98%" />
						</Row>
					</Col>
					<Col xs={24} lg={{ offset: 1, span: 11 }}>
						<InfoItem title="Report name" value={report_name} />
						<InfoItem title="Analysis date" value={analysis_date} />
						<InfoItem title="Risk range" value={risk_range} marginBottom={0} />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
