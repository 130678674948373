import { Button } from 'antd';
import styled from 'styled-components';

export const CustomButton = styled(Button)<{ $borderRadius?: string }>`
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};
	background-color: ${({ type }) => (type === 'primary' ? '#2f54eb' : null)};
	width: 100%;
	svg:hover {
		fill: #2f54eb;
	}
`;
