import { Paragraph, Title } from '@/components/Typography';
import { Col, Row } from 'antd';
import React from 'react';

import { CustomFormContainer as Container } from '../../styles';
import { BodyPartGoal } from './components/Inputs/BodyPartGoal';
import { FooterButtons } from './components/FooterButtons';

export function Goals() {
	return (
		<Row gutter={[0, 30]} justify="center">
			<Col offset={1} span={23}>
				<Row>
					<Col span={24}>
						<Title level={3}>Goals</Title>
					</Col>
					<Col>
						<Paragraph>Indicate the minimum acceptable exposure levels for the rotation</Paragraph>
					</Col>
				</Row>
			</Col>
			<Col span={23}>
				<Row justify="start" gutter={[0, 40]}>
					<Col span={23}>
						<Row>
							<Container span={23} style={{ backgroundColor: 'white', paddingLeft: '30px' }}>
								<Row gutter={[0, 5]}>
									<Col style={{ paddingBottom: '5px' }}>
										<Title level={4}>Minimum acceptable exposure level per body part</Title>
									</Col>
									<BodyPartGoal bodyPart="neck" />
									<BodyPartGoal bodyPart="trunk" />
									<BodyPartGoal bodyPart="left_upper_arm" />
									<BodyPartGoal bodyPart="right_upper_arm" />
									<BodyPartGoal bodyPart="left_lower_arm" />
									<BodyPartGoal bodyPart="right_lower_arm" />
								</Row>
							</Container>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<FooterButtons />
			</Col>
		</Row>
	);
}
