import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Table, Modal, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { CHECK_OPTIONS } from '../RotationReport/Report/Steps/Results/ReportTemplates/Footer/ButtonsCustom';
import { GetRotationReportsResponse, RotationDTO } from '@/types';
import { useDeleteRotationReport } from '@/hooks/Rotation';
import { useDownloadRotationReport } from '@/hooks';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Text } from '@/components/Typography';
import { queryClient } from '@/store/query';
import { Actions } from './Actions';
import * as S from './styles';

const { GET_ROTATION_REPORTS } = QUERY_KEYS;

interface ListProps {
	onShowFiles: (data: string) => void;
	data?: GetRotationReportsResponse;
	organization_id?: string;
	company_id?: string;
	isLoading: boolean;
}

export function List({ isLoading, data, onShowFiles, organization_id, company_id }: ListProps) {
	const history = useHistory();
	const deleteRotationReport = useDeleteRotationReport();
	const { mutateAsync: downloadPdf, isLoading: isDownloading } = useDownloadRotationReport();

	async function handleDownloadReport(rotation_report: RotationDTO): Promise<void> {
		const { id } = rotation_report;
		const { url } = await downloadPdf({
			organization_id,
			company_id,
			id,
			sections: CHECK_OPTIONS.map((m) => m.name)
		});
		window.open(url, '_blank');
	}

	async function handleDeleteReport(rotation_report: RotationDTO): Promise<void> {
		const { id } = rotation_report;
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this report?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				try {
					await deleteRotationReport.mutateAsync({ organization_id, company_id, id });
					queryClient.invalidateQueries([GET_ROTATION_REPORTS]);
					message.error(I18n.get('Report deleted successfully'));
				} catch (error: any) {
					let err = error?.response?.data?.message || 'Oops... Something went wrong';
					message.error(I18n.get(err));
				}
			}
		});
	}

	const columns: ColumnsType<RotationDTO> = [
		{
			key: 'name',
			ellipsis: true,
			align: 'center',
			dataIndex: 'name',
			sorter: (a, b) => sortByName(a.name, b.name),
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Report name')}</Text>
		},
		{
			ellipsis: true,
			align: 'center',
			key: 'riskRange',
			responsive: ['lg'],
			dataIndex: 'riskRange',
			render: (riskRange) => riskRange.name ?? '-',
			sorter: (a, b) => sortByName(a?.riskRange.name, b?.riskRange.name),
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Risk Range')}</Text>
		},
		{
			ellipsis: true,
			align: 'center',
			key: 'evaluator',
			responsive: ['lg'],
			dataIndex: 'evaluator',
			sorter: (a, b) => sortByName(a.evaluator.name, b.evaluator.name),
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Evaluator')}</Text>,
			render: (evaluator) => evaluator.name ?? '-'
		},
		{
			ellipsis: true,
			align: 'center',
			key: 'analysisDate',
			responsive: ['lg'],
			dataIndex: 'analysisDate',
			sorter: (a, b) => sortByDate(a.analysisDate, b.analysisDate),
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Creation date')}</Text>,
			render: (analysisDate) => (analysisDate ? moment(analysisDate).format('DD/MM/YYYY') : '--/--/----')
		},
		{
			key: 'actions',
			align: 'center',
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Actions')}</Text>,
			render: (_value, data) => (
				<Actions
					data={data}
					onShowFiles={onShowFiles}
					isDownloading={isDownloading}
					onEditActionClick={handleEditReport}
					onDownloadReport={handleDownloadReport}
					onDeleteActionClick={handleDeleteReport}
				/>
			)
		}
	];

	function sortByName(first: string | undefined, second: string | undefined) {
		if (!first || !second) {
			return -1;
		}
		return ('' + first).localeCompare(second);
	}

	function handleEditReport(rotation_report: RotationDTO): void {
		const { id } = rotation_report;
		const path = `/ehs/rotation/report/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		history.push(path);
	}

	const dataSource = data?.rows?.map((report, index) => ({ ...report, key: String(index + 1) }));

	function sortByDate(date_a: Date | string, date_b: Date | string): number {
		return moment.utc(new Date(date_b)).diff(moment.utc(new Date(date_a)));
	}

	return (
		<S.CustomTable span={24}>
			<Table
				rowKey="key"
				columns={columns}
				pagination={false}
				loading={isLoading}
				dataSource={dataSource}
				showSorterTooltip={false}
			/>
		</S.CustomTable>
	);
}
