import React from 'react';
import moment from 'moment';
import { Form, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { ColumnsType } from 'antd/lib/table';
import type { TableRowSelection } from 'antd/lib/table/interface';

import { PaginationCustom } from '@/components/ui/Pagination';
import { LIMIT, useSelectFilesContext } from '../../context';
import { dateFormat } from '@/utils/date-format';
import { TextOperation } from './TextOperation';
import * as S from './styles';

export type TableValues = {
	id: string;
	original_name: string;
	created_at: string | Date;
	organization_id: string;
	company_id: string;
	activity_id?: string;
	activity_name?: string;
};

const { useFormInstance } = Form;

export function FileTable() {
	const { setFieldValue, getFieldValue } = useFormInstance();
	const { allFiles, loadingAllFiles, page, selectedRowKeys, handlePage, handlingSelectRowKeys } =
		useSelectFilesContext();

	const columns: ColumnsType<TableValues> = [
		{
			align: 'center',
			key: 'original_name',
			title: I18n.get('Files'),
			className: 'file-columns',
			dataIndex: 'original_name'
		},
		{
			align: 'center',
			key: 'workstation_name',
			dataIndex: 'workstation_name',
			title: I18n.get('Workstation')
		},
		{
			width: '20%',
			align: 'center',
			key: 'created_at',
			responsive: ['xxl'],
			dataIndex: 'created_at',
			title: I18n.get('Date'),
			render: (date: string) => {
				return moment(date).format(dateFormat());
			}
		},
		{
			width: '30%',
			align: 'center',
			key: 'activity',
			dataIndex: 'activity',
			title: I18n.get('Operation'),
			render: (_, file: TableValues) => <TextOperation file={file} selectedRowKeys={selectedRowKeys} />
		}
	];

	const rowSelection: TableRowSelection<TableValues> = {
		selectedRowKeys,
		preserveSelectedRowKeys: true,
		onChange: (selectedRowKeys: React.Key[]) => {
			const files = selectedRowKeys.map((id) => ({
				id,
				activity: getFieldValue([id, 'activity_name'])
			}));
			setFieldValue(['selectedRowKeys'], selectedRowKeys);
			handlingSelectRowKeys(selectedRowKeys);
			files.forEach((file) => {
				if (file.activity) {
					setFieldValue([file.id, 'activity_name'], file.activity);
				}
			});
		}
	};

	return (
		<S.Table span={23}>
			<Form.Item
				name="items"
				rules={[
					() => ({
						validator() {
							if (selectedRowKeys.length === 0) {
								return Promise.reject(Error('Select a file'));
							}
							return Promise.resolve();
						}
					})
				]}
			>
				<Table
					rowKey="id"
					size={'large'}
					columns={columns}
					pagination={false}
					dataSource={allFiles?.rows ?? []}
					rowSelection={{ type: 'checkbox', hideSelectAll: true, ...rowSelection }}
				/>
			</Form.Item>
			<PaginationCustom
				page={page}
				maxPage={LIMIT}
				setPage={handlePage}
				loading={loadingAllFiles}
				hasItemPerPage={false}
				total={allFiles?.count ?? 0}
			/>
		</S.Table>
	);
}
