import React from 'react';
import type { InputProps } from 'antd';

import { Text } from '@/components/Typography';

type PercentageInputProps = InputProps;

export function PercentageInput(props: Readonly<PercentageInputProps>) {
	return <Text style={{ fontSize: '16px', fontWeight: 'bolder' }}>{props.value || 0}%</Text>;
}
