import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useSelectFilesContext } from '../../context';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import * as S from './styles';

export function NextButton() {
	const { handleValidation } = useSelectFilesContext();
	const { handlePreviousStep } = useRotationReportContext();

	async function handleNext() {
		await handleValidation();
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<S.CustomButton
					style={{
						backgroundColor: '#fff'
					}}
					htmlType="button"
					size="large"
					type="ghost"
					onClick={handlePreviousStep}
				>
					{I18n.get('Previous')}
				</S.CustomButton>
			</Col>
			<Col>
				<S.CustomButton htmlType="button" size="large" type="primary" onClick={handleNext}>
					{I18n.get('Next')}
				</S.CustomButton>
			</Col>
		</Row>
	);
}
