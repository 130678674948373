import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, message } from 'antd';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { useUpdateRotationReportStep } from '@/hooks/useUpdateRotationReportStep';
import * as S from '../styles';

const { useFormInstance } = Form;

export function FooterButtons() {
	const { getFieldValue } = useFormInstance();
	const { handleNextStep, handlePreviousStep, rotationReport, basicParams } = useRotationReportContext();
	const { mutateAsync: updateStep } = useUpdateRotationReportStep();

	async function handleOnNextStep(): Promise<void> {
		try {
			const activities_item = getFieldValue('activities_item');
			if (!activities_item) {
				message.error('Add at least one activity');
				return;
			}
			await updateStep({
				step: '4',
				...basicParams,
				id: rotationReport?.id
			});
			handleNextStep();
		} catch (error: any) {
			let err = error?.response?.data?.message || 'Oops... Something went wrong';
			message.error(I18n.get(err));
		}
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<S.CustomButton
					style={{
						backgroundColor: '#fff'
					}}
					htmlType="button"
					size="large"
					type="ghost"
					onClick={handlePreviousStep}
				>
					{I18n.get('Previous')}
				</S.CustomButton>
			</Col>
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={handleOnNextStep}>
					{I18n.get('Next')}
				</S.CustomButton>
			</Col>
		</Row>
	);
}
