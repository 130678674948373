import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { Key } from 'rc-tree/lib/interface';
import { DownloadOutlined } from '@ant-design/icons';

import { Paragraph, Text, Title } from '@/components/Typography';
import { CustomButton } from '@/components/ui/Button/styles';
import { ModalBorder } from '@/components/ui';
import { CustomTree, DownloadButton } from './styles';

type MultipleCheckbox = Key[] | { checked: Key[]; halfChecked: Key[] };

export type onDownloadPdfFunction = {
	sections: string[];
};

interface ButtonsCustomProps {
	isLoadingPDF: boolean;
	onDownloadPDF(params: onDownloadPdfFunction): Promise<void>;
}

export const CHECK_OPTIONS = [
	{
		label: 'Video files',
		name: 'files'
	},
	{
		label: 'Goals',
		name: 'goals'
	},
	{
		label: 'Exposure',
		name: 'exposure'
	},
	{
		label: 'Comment',
		name: 'comment'
	}
];

export function ButtonsCustom({ isLoadingPDF, onDownloadPDF }: Readonly<ButtonsCustomProps>) {
	const [showModal, setShowModal] = useState(false);
	const [checkBox, setCheckBox] = useState<MultipleCheckbox>(CHECK_OPTIONS.map((m) => m.name));

	function handleDownloadPDF() {
		setShowModal(true);
	}

	function handleOnCancel() {
		setShowModal(false);
	}

	function onCheck(checked: MultipleCheckbox): void {
		setCheckBox(checked);
	}

	async function handleGenerateDocument() {
		onDownloadPDF({
			sections: checkBox as string[]
		});
	}

	const treeData = CHECK_OPTIONS.map((item) => ({
		selectable: false,
		key: item.name,
		style: { fontWeight: 'bold' },
		title: I18n.get(item.label)
	}));

	return (
		<React.Fragment>
			<Row justify="end" gutter={[30, 0]}>
				<Col>
					<DownloadButton
						ghost
						size="large"
						type="primary"
						loading={isLoadingPDF}
						onClick={handleDownloadPDF}
						icon={<DownloadOutlined />}
					>
						<Text>Download PDF</Text>
					</DownloadButton>
				</Col>
			</Row>
			<ModalBorder
				width="560px"
				footer={false}
				open={showModal}
				$borderSize="15px"
				onCancel={handleOnCancel}
				style={{ maxWidth: '90vw' }}
			>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Title level={3} style={{ textAlign: 'center' }}>
							PDF
						</Title>
					</Col>
					<Col span={23} offset={1}>
						<Paragraph style={{ textAlign: 'start', fontSize: 16 }}>
							Select the items you want to present in your document
						</Paragraph>
					</Col>
					<Col span={21}>
						<CustomTree
							checkable
							defaultExpandAll
							treeData={treeData}
							disabled={isLoadingPDF}
							onCheck={(checked) => onCheck(checked)}
							defaultCheckedKeys={CHECK_OPTIONS?.map((item) => item.name)}
						/>
					</Col>
					<Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
						<CustomButton
							size="large"
							onClick={handleOnCancel}
							style={{ margin: '0 2rem 0 0' }}
							disabled={isLoadingPDF}
						>
							{I18n.get('Cancel')}
						</CustomButton>
						<CustomButton
							loading={isLoadingPDF}
							size="large"
							onClick={handleGenerateDocument}
							type="primary"
						>
							{I18n.get('Generate document')}
						</CustomButton>
					</Col>
				</Row>
			</ModalBorder>
		</React.Fragment>
	);
}
