import React, { useEffect } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, DatePicker, Form } from 'antd';
import { Text } from '@/components/Typography';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

interface PeriodProps {
	fontSize?: string;
}

export function Period({ fontSize }: PeriodProps) {
	const { validateFields } = useFormInstance();
	const startDate = useWatch(['filter', 'created_at_start']);
	const endDate = useWatch(['filter', 'created_at_end']);

	useEffect(() => {
		if (startDate || endDate) {
			validatedDateInputs();
		}
	}, [startDate, endDate]);

	async function validatedDateInputs() {
		await validateFields();
	}

	return (
		<Col xs={23}>
			<Row gutter={[20, 0]} align="middle">
				<Col span={11}>
					<S.CustomFormItem
						label={<Text style={{ fontSize }}>Period</Text>}
						style={{ marginBottom: 0 }}
						labelCol={{ span: 24 }}
						name={['filter', 'created_at_start']}
						rules={[
							() => ({
								validator() {
									if (startDate && !endDate) {
										return Promise.reject(Error('Select the end date'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<S.ContainerDate $borderRadius="8px">
							<DatePicker
								allowClear
								size="large"
								format={'L'}
								placeholder={I18n.get('Start date')}
								style={{ width: '100%' }}
							/>
						</S.ContainerDate>
					</S.CustomFormItem>
				</Col>
				<Col
					span={2}
					style={{
						textAlign: 'center',
						marginTop: 34,
						padding: 0
					}}
				>
					<Text style={{ fontSize: '16px' }}>until</Text>
				</Col>

				<Col span={11}>
					<S.CustomFormItem
						label={<Text></Text>}
						labelCol={{ span: 24 }}
						style={{ marginBottom: 0 }}
						name={['filter', 'created_at_end']}
						rules={[
							() => ({
								validator() {
									if (moment(endDate).isBefore(startDate) && endDate) {
										return Promise.reject(Error('Date cannot larger than start date'));
									} else if (!startDate && endDate) {
										return Promise.reject(Error('Select the start date'));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<S.ContainerDate $borderRadius="8px">
							<DatePicker
								placeholder={I18n.get('End date')}
								allowClear
								size="large"
								format={'L'}
								style={{ width: '100%' }}
							/>
						</S.ContainerDate>
					</S.CustomFormItem>
				</Col>
			</Row>
		</Col>
	);
}
