import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Grid, message, Row, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';

import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { RotationReportResultResponse } from '@/hooks/useGetRotationReportResult';
import { RotationActivityFileResult } from '@/hooks/useGetRotationReportResult';
import { Clapperboard } from '@/assets/icons/Clapperboard';
import { useDownloadFile } from '@/hooks/useDownloadFile';
import { Text, Title } from '@/components/Typography';
import * as S from './styles';

interface ActivitiesFilesProps {
	files?: RotationReportResultResponse['files'];
}

export type TableValues = RotationActivityFileResult;

export function ActivitiesFiles({ files }: Readonly<ActivitiesFilesProps>) {
	const { basicParams } = useRotationReportContext();
	const { mutateAsync: downloadFile } = useDownloadFile();

	const breakpoint = Grid.useBreakpoint();
	const width = breakpoint.xl ? undefined : `${100 / 3}%`;

	async function downloadFileVideo(file: RotationActivityFileResult) {
		if (!file.file_id) {
			return;
		}
		try {
			const url = await downloadFile({
				id: file.file_id,
				original_name: file.original_name,
				company_id: basicParams.company_id,
				organization_id: basicParams.organization_id
			});

			let element = document.createElement('a');
			element.target = '_blank';
			element.href = url;
			element.click();
			element.remove();
		} catch (error) {
			message.error(I18n.get('Failed to download file'));
		}
	}

	const columns: ColumnsType<TableValues> = [
		{
			key: 'placeholder',
			align: 'center',
			dataIndex: 'placeholder',
			width: 140,
			title: <span />,
			className: 'bg-blue',
			render: (placeholder) => `${I18n.get(placeholder.name)} ${placeholder.value}`
		},
		{
			align: 'center',
			key: 'name',
			width: width ?? '25%',
			dataIndex: 'name',
			title: <Text>Operation</Text>
		},
		{
			align: 'center',
			responsive: ['lg'],
			key: 'original_name',
			width,
			title: <Text>File</Text>,
			dataIndex: 'original_name'
		},

		{
			align: 'center',
			key: 'download',
			width: 90,
			title: <Text style={{ wordBreak: 'keep-all' }}>Action</Text>,
			render: (file: RotationActivityFileResult) =>
				file.file_id ? (
					<Button
						type="link"
						onClick={() => downloadFileVideo(file)}
						icon={<Clapperboard style={{ fontSize: '1.7rem' }} />}
					/>
				) : null
		}
	];

	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={2} style={{ color: '#262626' }}>
					Operations and videos
				</Title>
			</Col>
			<S.Table span={22} $backGroundColor="#F8F8F8" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
				<Table rowKey="id" size={'large'} columns={columns} pagination={false} dataSource={files} />
			</S.Table>
		</Row>
	);
}
