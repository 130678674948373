import React from 'react';
import { Row, Col, Divider } from 'antd';

import { Paragraph, Title } from '@/components/Typography';
import { FooterButtons } from './components/FooterButtons';
import { ActivityTabs } from './components/ActivityTabs';
import { CycleResult } from './components/CycleResult';

export function Evaluation() {
	return (
		<Row gutter={[0, 30]} justify="center">
			<Col span={23}>
				<Row>
					<Col span={24}>
						<Title level={3}>Evaluation of the rotation</Title>
					</Col>
					<Col>
						<Paragraph>
							Select the operations that will make up the rotation and define the order and frequency
						</Paragraph>
					</Col>
				</Row>
			</Col>
			<Col span={23}>
				<Row justify="center" gutter={[0, 40]}>
					<Col span={24}>
						<ActivityTabs />
					</Col>
					<Divider style={{ borderColor: '#26262633' }} />
					<Col span={24}>
						<CycleResult />
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<FooterButtons />
			</Col>
		</Row>
	);
}
