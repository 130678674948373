import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Row, Col, Button, Spin } from 'antd';
import { I18n } from '@aws-amplify/core';

import type { RotationDTO } from '@/types';
import { TooltipCustom } from './Tooltip';
import * as S from './styles';

interface ActionsProps {
	data: RotationDTO;
	isDownloading: boolean;
	onShowFiles: (rotation_id: string) => void;
	onDownloadReport: (rowData: RotationDTO) => Promise<void>;
	onEditActionClick: (rowData: RotationDTO) => void;
	onDeleteActionClick: (rowData: RotationDTO) => Promise<void>;
}

export function Actions({
	data,
	onShowFiles,
	isDownloading,
	onDownloadReport,
	onEditActionClick,
	onDeleteActionClick
}: ActionsProps) {
	function handleEditReport(): void {
		onEditActionClick(data);
	}

	async function handleDeleteFile(rowData: RotationDTO): Promise<void> {
		onDeleteActionClick(rowData);
	}

	return (
		<Row justify="center" align="middle">
			<S.ActionsInline xs={24}>
				<Row justify="center" align="middle" gutter={[10, 0]}>
					<Col span={6}>
						<TooltipCustom title={I18n.get('Edit')}>
							<Button type="link" size="large" icon={<S.EditIcon />} onClick={handleEditReport} />
						</TooltipCustom>
					</Col>
					<Col span={6}>
						<TooltipCustom title={I18n.get('Download')}>
							<Button
								type="link"
								size="large"
								icon={isDownloading ? <Spin /> : <S.DownloadFileIcon />}
								onClick={() => onDownloadReport(data)}
							/>
						</TooltipCustom>
					</Col>
					<Col span={6}>
						<TooltipCustom title={I18n.get('Files')}>
							<Button
								type="link"
								size="large"
								icon={<Icon component={S.FilesIcon} />}
								onClick={() => onShowFiles(data.id)}
							/>
						</TooltipCustom>
					</Col>
					<Col span={6}>
						<TooltipCustom title={I18n.get('Delete')}>
							<Button
								type="link"
								size="large"
								icon={<S.DeleteFileIcon />}
								onClick={() => handleDeleteFile(data)}
							/>
						</TooltipCustom>
					</Col>
				</Row>
			</S.ActionsInline>
		</Row>
	);
}
