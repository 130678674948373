import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { CustomButton } from '../../Evaluation/components/styles';

export function FooterButtons() {
	const history = useHistory();
	const { handlePreviousStep } = useRotationReportContext();

	function handleClose(): void {
		history.push('/ehs/rotation');
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<CustomButton
					size="large"
					type="ghost"
					htmlType="button"
					onClick={handlePreviousStep}
					style={{ backgroundColor: '#fff' }}
				>
					{I18n.get('Back')}
				</CustomButton>
			</Col>
			<Col>
				<CustomButton htmlType="submit" size="large" type="primary" onClick={handleClose}>
					{I18n.get('Close')}
				</CustomButton>
			</Col>
		</Row>
	);
}
