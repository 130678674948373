import React from 'react';
import { Row, Col } from 'antd';

import { useGetRotationReportCycleTotalTime } from '@/hooks/useGetRotationReportCycleTotalTime';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { OverallExposure } from './OverallExposure';
import { Title } from '@/components/Typography';
import { ActivityList } from './ActivityList';
import { NewActivity } from './NewActivity';
import { Card, TimerResult } from '../styles';

export function formatTime(seconds: number | string): string {
	const number_seconds = Number(seconds);
	const hours = Math.floor(number_seconds / 3600);
	const minutes = Math.floor((number_seconds % 3600) / 60);
	const secs = number_seconds % 60;

	return `${hours}h ${String(minutes).padStart(2, '0')}m ${String(secs).padStart(2, '0')}s`;
}

export function CycleResult() {
	const { basicParams, rotationReport } = useRotationReportContext();
	const { data } = useGetRotationReportCycleTotalTime({
		...basicParams,
		id: rotationReport?.id
	});

	return (
		<Row>
			<Card span={24}>
				<Row justify="space-between">
					<Col>
						<Title level={4}>Exposure of selected operations</Title>
					</Col>
					<Col>
						<TimerResult>{formatTime(data?.cycle_total_time ?? 0)}</TimerResult>
					</Col>
				</Row>
				<NewActivity />
				<ActivityList />
				{data?.cycle_total_time && <OverallExposure />}
			</Card>
		</Row>
	);
}
