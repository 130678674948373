import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Company, Line, Organization, Period, Sector, Workstation } from '../Inputs';
import { GetFilesActivityDTO } from '@/hooks/useGetFilesWithActivity';
import { useSelectFilesContext } from '../../../../context';
import { Title } from '@/components/Typography';
import { ModalBorder } from '@/components/ui';
import * as S from '../../styles';

const { useFormInstance } = Form;

interface FilterModalProps {
	openModal: boolean;
	onOpenModal: () => void;
}

export function FilterModal({ openModal, onOpenModal }: Readonly<FilterModalProps>) {
	const { getFieldsValue, resetFields } = useFormInstance();
	const { handleFilter, handlePage, handlingSelectRowKeys } = useSelectFilesContext();

	function finishFilter(): void {
		const values: { filter: GetFilesActivityDTO } = getFieldsValue(true);
		const { name, ...rest } = values.filter;
		handleFilter(rest);
		handlingSelectRowKeys([]);
		onOpenModal();
	}

	function clearFilter(): void {
		handlingSelectRowKeys([]);
		handleFilter({} as GetFilesActivityDTO);
		resetFields(['filter']);
		handlePage(1);
		onOpenModal();
	}

	const fontSize = '18px';

	return (
		<ModalBorder $borderSize="25px" open={openModal} onCancel={onOpenModal} footer={false}>
			<Row gutter={[0, 20]}>
				<Col span={24}>
					<Row>
						<Col>
							<Title level={3}>Filters</Title>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Row justify="center" gutter={[0, 8]}>
						<Organization fontSize={fontSize} />
						<Company fontSize={fontSize} />
						<Sector fontSize={fontSize} />
						<Line fontSize={fontSize} />
						<Workstation fontSize={fontSize} />
						<Period fontSize={fontSize} />
					</Row>
				</Col>
				<Col span={24}>
					<Row justify="center" gutter={[10, 0]}>
						<Col>
							<S.CustomButton size="large" onClick={clearFilter} type="default">
								{I18n.get('Clear')}
							</S.CustomButton>
						</Col>
						<Col>
							<S.CustomButton size="large" onClick={finishFilter} type="primary">
								{I18n.get('Filter')}
							</S.CustomButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</ModalBorder>
	);
}
