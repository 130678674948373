import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { OrganizationSelect } from '@/components/ui/Inputs/OrganizationSelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useOrganization } from '@/hooks';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

interface OrganizationProps {
	namePlace?: string;
	fontSize?: string;
	isRequired?: boolean;
}

export function Organization({ namePlace = 'filter', fontSize, isRequired = false }: OrganizationProps) {
	const { organizations } = useOrganization();
	const { setFieldValue } = useFormInstance();

	const organizationId = useWatch([namePlace, 'organization_id']);

	function handleValue(id: string) {
		setFieldValue([namePlace, 'organization_id'], id);
	}

	function handleClear() {
		setFieldValue([namePlace, 'organization_id'], undefined);
		setFieldValue([namePlace, 'company_id'], undefined);
		setFieldValue([namePlace, 'line_id'], undefined);
		setFieldValue([namePlace, 'sector_id'], undefined);
	}

	return (
		<Col span={23}>
			<S.CustomFormItem
				required={false}
				labelCol={{ span: 24 }}
				style={{ marginBottom: 0 }}
				name={[namePlace, 'organization_id']}
				rules={[{ required: true, message: I18n.get('Select the company') }]}
				label={<RequiredLabel fontSize={fontSize} title="Company" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<OrganizationSelect
						size="large"
						loading={false}
						disabled={false}
						organizations={organizations.data}
						onClear={handleClear}
						onChange={handleValue}
						organizationId={organizationId}
					/>
				</S.ContainerSelect>
			</S.CustomFormItem>
		</Col>
	);
}
