import React from 'react';
import { Row, Col, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Title } from '@/components/Typography';
import { DividerCustom } from '@/components/ui/DividerCustom';
import { useDownloadRotationReport } from '@/hooks/useDownloadRotationReport';
import { useGetRotationReportResult } from '@/hooks/useGetRotationReportResult';
import { useUpdateRotationReportComment } from '@/hooks/useUpdateRotationReportComment';

import { ButtonsCustom, onDownloadPdfFunction } from './ReportTemplates/Footer/ButtonsCustom';
import { useRotationReportContext } from '../../../context';
import { ActivitiesFiles } from './ActivitiesFiles';
import { ReportTemplate } from './ReportTemplates';
import { OverallResults } from './OverallResults';
import { FooterButtons } from './FooterButtons';
import { Information } from './Information';
import { Exposure } from './Exposure';
import { Goals } from './Goals';

export function Results() {
	const { basicParams, rotationReport } = useRotationReportContext();
	const { data } = useGetRotationReportResult({
		...basicParams,
		id: rotationReport?.id,
		locale: navigator.language
	});
	const { mutateAsync: updateRotationReportComment } = useUpdateRotationReportComment();
	const { mutateAsync: downloadReport, isLoading: isDownloading } = useDownloadRotationReport();

	async function handleSaveComment(comment: string) {
		if (comment === rotationReport?.comment) {
			return;
		}
		await updateRotationReportComment({
			id: rotationReport?.id as string,
			...basicParams,
			comment
		});
	}

	async function handleDownloadPDF({ sections }: onDownloadPdfFunction): Promise<void> {
		try {
			const { url } = await downloadReport({
				id: rotationReport?.id as string,
				locale: navigator.language,
				...basicParams,
				sections
			});

			let element = document.createElement('a');
			element.target = '_blank';
			element.href = url;
			element.click();
			element.remove();
		} catch (error: any) {
			message.error(I18n.get(error?.response?.data?.message || 'Failed to download file'));
		}
	}

	return (
		<Row justify="center" gutter={[0, 22]}>
			<Col span={22}>
				<Row justify={'space-between'} align={'middle'}>
					<Col>
						<Title level={3} style={{ color: '#262626' }}>
							Results
						</Title>
					</Col>
					<Col>
						<ButtonsCustom isLoadingPDF={isDownloading} onDownloadPDF={handleDownloadPDF} />
					</Col>
				</Row>
			</Col>
			<Col span={23}>
				<ReportTemplate
					onCommentSave={handleSaveComment}
					onDownloadPDF={handleDownloadPDF}
					isLoadingPDF={isDownloading}
				>
					<Information informations={data?.informations} />
					<ReportDivider />
					<ActivitiesFiles files={data?.files ?? []} />
					<ReportDivider />
					<OverallResults total_time={data?.total_cycle_time} activities={data?.activities ?? []} />
					<ReportDivider />
					<Goals goals={data?.goals ?? []} />
					<ReportDivider />
					<Exposure exposures={data?.exposures ?? []} legends={data?.exposures_legend ?? []} />
				</ReportTemplate>
			</Col>
			<Col>
				<FooterButtons />
			</Col>
		</Row>
	);
}

function ReportDivider() {
	return (
		<Row justify={'center'}>
			<DividerCustom span={23} color="#E6E8E8" thickness="1px" margin={'30px 0'} />
		</Row>
	);
}
