import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { QUERY_KEYS } from '@/utils/query-keys';
import { queryClient } from '@/store/query';
import Api from '@/services/api';

export type UpdateRotationReportActivityDTO = {
	rotation_activity_id?: string;
	organization_id?: string;
	company_id?: string;
	frequency?: number;
	id?: string;
};

export type RotationReportActivityResponse = {
	message: string;
};

async function updateRotationReportActivity(params: UpdateRotationReportActivityDTO): Promise<any> {
	const url = `/rotation/report/${params.id}/activity`;
	const { data } = await Api.put(url, params);
	return data;
}

export const useUpdateRotationReportActivity = () => {
	return useMutation((prams: UpdateRotationReportActivityDTO) => updateRotationReportActivity(prams), {
		onSuccess: ({ message: txt }: RotationReportActivityResponse) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_ACTIVITIES]);
			message.success(I18n.get(txt));
		}
	});
};
