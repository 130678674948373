import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { Organization } from './Components/Inputs/Organization';
import { AnalysisDate } from './Components/Inputs/AnalysisDate';
import { Workstation } from './Components/Inputs/Workstation';
import { ReportName } from './Components/Inputs/ReportName';
import { Evaluator } from './Components/Inputs/Evaluator';
import { Activity } from './Components/Inputs/Activity';
import { Company } from './Components/Inputs/Company';
import { Sector } from './Components/Inputs/Sector';
import { Line } from './Components/Inputs/Line';
import { GetRotationReportsDTO } from '@/types';
import * as S from './styles';

const { Panel } = Collapse;
const { useForm } = Form;

interface FilterProps {
	onFilterClick(filterData: GetRotationReportsDTO): void;
	initialValues: GetRotationReportsDTO;
	loading: boolean;
}

export function Filter({ onFilterClick, loading, initialValues }: FilterProps) {
	const [form] = useForm();
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	function handleClear() {
		history.push({ pathname: '/ehs/rotation', search: '?cleared=true' });
		form.resetFields();
	}

	function handleFinish(values: GetRotationReportsDTO) {
		if (values.analysis_date) {
			values.analysis_date = moment(values.analysis_date).format();
		}

		updateURLParams(values);
		onFilterClick(values);
	}

	function updateURLParams(params: GetRotationReportsDTO) {
		for (const key in params) {
			searchParams.delete('cleared');
			searchParams.delete(key);
			if (Object.prototype.hasOwnProperty.call(params, key)) {
				const typedKey: keyof GetRotationReportsDTO = key as any;
				const param = params[typedKey];
				if (param) {
					searchParams.set(key, param as string);
				}
			}
		}

		history.push({
			pathname: '/ehs/rotation',
			search: searchParams.toString()
		});
	}

	return (
		<S.CollapseStyled defaultActiveKey={search ? '1' : '0'}>
			<Panel key={'1'} header={I18n.get('Filter')}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleFinish}
					initialValues={{
						...initialValues,
						analysis_date: initialValues.analysis_date ? moment(initialValues.analysis_date) : null
					}}
				>
					<Row gutter={26}>
						<Col span={12}>
							<ReportName />
						</Col>
						<Col span={6}>
							<Evaluator />
						</Col>
						<Col span={6}>
							<AnalysisDate />
						</Col>
					</Row>
					<Row gutter={26}>
						<Col span={6}>
							<Organization />
						</Col>
						<Col span={6}>
							<Company />
						</Col>
						<Col span={6}>
							<Sector />
						</Col>
						<Col span={6}>
							<Line />
						</Col>
					</Row>
					<Row gutter={26}>
						<Col span={6}>
							<Workstation />
						</Col>
						<Col span={6}>
							<Activity />
						</Col>
					</Row>
					<Row gutter={13} style={{ paddingTop: '20px' }}>
						<Col sm={3} style={{ marginLeft: '15px' }}>
							<Button
								type="primary"
								htmlType="submit"
								icon={<SearchOutlined />}
								style={{ width: '100%', borderRadius: '6px' }}
								loading={loading}
							>
								{I18n.get('Search')}
							</Button>
						</Col>
						<Col sm={3}>
							<Button style={{ width: '75%', borderRadius: '6px' }} onClick={handleClear}>
								{I18n.get('Clear')}
							</Button>
						</Col>
					</Row>
				</Form>
			</Panel>
		</S.CollapseStyled>
	);
}
