import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useWorkstations } from '@/hooks';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

interface WorkstationProps {
	namePlace?: string;
	fontSize?: string;
	isRequired?: boolean;
}

export function Workstation({ namePlace = 'filter', fontSize, isRequired = false }: WorkstationProps) {
	const { setFieldValue, getFieldValue } = useFormInstance();

	const line_id = getFieldValue([namePlace, 'line_id']);
	const organization_id = useWatch([namePlace, 'organization_id']);
	const company_id = useWatch([namePlace, 'company_id']);
	const workstation_id = useWatch([namePlace, 'workstation_id']);

	const { isFetching, data } = useWorkstations({ organization_id, company_id, line_id });

	function handleOnChange(id: string): void {
		setFieldValue([namePlace, 'workstation_id'], id);
		setFieldValue([namePlace, 'products'], undefined);
	}

	return (
		<Col span={23}>
			<S.CustomFormItem
				required={false}
				labelCol={{ span: 24 }}
				style={{ marginBottom: 0 }}
				name={[namePlace, 'workstation_id']}
				rules={[{ required: true, message: I18n.get('Select the workstation') }]}
				label={<RequiredLabel fontSize={fontSize} title="Workstation" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<WorkstationSelect
						size="large"
						lineId={line_id}
						workstations={data}
						disabled={!line_id}
						loading={isFetching}
						allowCreation={false}
						onChange={handleOnChange}
						workstationId={workstation_id}
						fieldName={[namePlace, 'workstation_id']}
					/>
				</S.ContainerSelect>
			</S.CustomFormItem>
		</Col>
	);
}
