import React from 'react';
import { Row, Col, Form, message } from 'antd';
import { queryClient } from '@/store/query';
import { I18n } from '@aws-amplify/core';

import { useCreateRotationObjectives } from '@/hooks/Rotation/useCreateRotationGoals';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { useApplicationContext } from '@/context/application';
import { QUERY_KEYS } from '@/utils/query-keys';
import * as S from './styles';

const { useFormInstance } = Form;
const { GET_ROTATION_OBJECTIVES, GET_ROTATION_REPORT } = QUERY_KEYS;

type NumberObject = {
	[key: string]: number;
};

export function FooterButtons() {
	const { validateFields, getFieldsValue } = useFormInstance();

	const { handlePreviousStep, handleNextStep, rotationReport } = useRotationReportContext();
	const { organization, company } = useApplicationContext();

	const createRotationObjectives = useCreateRotationObjectives();

	async function handleNext() {
		await validateFields();
		const { goals } = await getFieldsValue();
		const body_parts_goals = normalizeObjectives(goals);

		try {
			await createRotationObjectives.mutateAsync({
				body_parts_goals,
				organization_id: organization?.id,
				company_id: company?.id,
				rotation_report_id: rotationReport?.id
			});

			queryClient.invalidateQueries([GET_ROTATION_OBJECTIVES]);
			queryClient.invalidateQueries([GET_ROTATION_REPORT]);
		} catch (error: any) {
			const err = error?.response?.data?.message || "Failed to create rotation's objectives";
			message.error(I18n.get(err));
		}
		handleNextStep();
	}

	function normalizeObjectives(objectives: NumberObject): NumberObject {
		const body_parts_goals: NumberObject = Object.fromEntries(
			Object.entries(objectives).filter(([_, value]) => value > 0)
		);

		return body_parts_goals;
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<S.CustomButton
					style={{ backgroundColor: '#fff' }}
					htmlType="button"
					size="large"
					type="ghost"
					onClick={handlePreviousStep}
				>
					{I18n.get('Previous')}
				</S.CustomButton>
			</Col>
			<Col>
				<S.CustomButton htmlType="button" size="large" type="primary" onClick={handleNext}>
					{I18n.get('Next')}
				</S.CustomButton>
			</Col>
		</Row>
	);
}
