import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Divider, Form, Grid, InputNumber, message, Modal, Row } from 'antd';
import { EditOutlined, DeleteOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useUpdateRotationReportActivitySequence } from '@/hooks/useUpdateRotationReportActivitySequence';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { useDeleteRotationReportActivity } from '@/hooks/useDeleteRotationReportActivity';
import { useUpdateRotationReportActivity } from '@/hooks/useUpdateRotationReportActivity';
import { useUpdateRotationReportExposure } from '@/hooks/useUpdateRotationReportExposure';
import Icon from '@ant-design/icons/lib/components/Icon';
import ArrowDown from '@/assets/icons/ArrowDown';
import { Text } from '@/components/Typography';
import ArrowUp from '@/assets/icons/ArrowUp';
import { formatTime } from '.';
import * as S from '../styles';

interface ActivityListItemProps {
	data: {
		id: string;
		sequence: number;
		frequency: number;
		total_time: number;
		activity_name: string;
	};
	isDisabledUp: boolean;
	isDisabledEdit: boolean;
	isDisabledDown: boolean;
}

interface FrequencyProps {
	id: string;
	name: string[];
	disabled: boolean;
	default_value: number;
}

const { useFormInstance } = Form;

export function ActivityListItem({
	data: { id, sequence, frequency, total_time, activity_name },
	isDisabledUp,
	isDisabledDown,
	isDisabledEdit
}: ActivityListItemProps) {
	const { setFieldValue, getFieldValue } = useFormInstance();
	const { rotationReport, basicParams } = useRotationReportContext();
	const [showEdit, setShowEdit] = useState(false);

	const breakpoints = Grid.useBreakpoint();

	const iconSize = breakpoints.xxl ? 24 : 22;

	const { mutateAsync: updateRotationReportActivitySequence } = useUpdateRotationReportActivitySequence();
	const { mutateAsync: updateRotationReportActivity, isLoading } = useUpdateRotationReportActivity();
	const { mutateAsync: deleteRotationReportActivity } = useDeleteRotationReportActivity();
	const { mutateAsync: updateRotationReportExposure } = useUpdateRotationReportExposure();

	const frequency_name = [`item-${id}`, 'frequency'];

	function handleEdit() {
		setFieldValue(frequency_name, frequency);
		setShowEdit(true);
	}

	async function handleEditFinish() {
		const frequency = getFieldValue(frequency_name);
		await updateRotationReportActivity({
			rotation_activity_id: id,
			id: rotationReport?.id,
			...basicParams,
			frequency
		});
		setShowEdit(false);
		await updateRotationReportExposure({
			id: rotationReport?.id,
			...basicParams
		});
	}

	async function handleUp(id: string) {
		await updateRotationReportActivitySequence({
			id: rotationReport?.id,
			...basicParams,
			rotation_activity_id: id,
			position: 'up'
		});
	}

	async function handleDown(id: string) {
		await updateRotationReportActivitySequence({
			id: rotationReport?.id,
			...basicParams,
			rotation_activity_id: id,
			position: 'down'
		});
	}

	async function handleDelete(id: string) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this operation?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				deleteActivity(id);
			}
		});
	}

	async function deleteActivity(id: string) {
		try {
			await deleteRotationReportActivity({
				...basicParams,
				rotation_activity_id: id,
				id: rotationReport?.id as string
			});
			await updateRotationReportExposure({
				id: rotationReport?.id,
				...basicParams
			});
		} catch (error: any) {
			let err = error?.response?.data?.message || 'Oops... Something went wrong';
			message.error(I18n.get(err));
		}
	}

	return (
		<React.Fragment>
			<Row justify="space-between" align="middle" style={{ height: 50, marginTop: 5 }}>
				<Col xl={3} sm={0}>
					<Text className="text-center bold">{sequence}°</Text>
				</Col>
				<Col xl={8} sm={9}>
					<Text>{activity_name}</Text>
				</Col>
				<Col span={3}>
					{showEdit ? (
						<Frequency id={id} disabled={isLoading} default_value={frequency} name={frequency_name} />
					) : (
						<Text className="text-center">{frequency}</Text>
					)}
				</Col>
				<Col xl={6} sm={8}>
					<Text className="text-center">{formatTime(total_time)}</Text>
				</Col>
				<Col span={3} style={{ display: 'flex', justifyContent: 'space-around' }}>
					{showEdit ? (
						<Button
							type="link"
							icon={<CheckOutlined style={{ fontSize: iconSize }} />}
							disabled={isLoading}
							onClick={handleEditFinish}
						/>
					) : (
						<Button
							type="link"
							disabled={isDisabledEdit}
							icon={<EditOutlined style={{ fontSize: iconSize }} />}
							onClick={handleEdit}
						/>
					)}
					<Button
						type="link"
						onClick={() => handleDelete(id)}
						icon={<DeleteOutlined style={{ fontSize: iconSize, color: '#E74150' }} />}
					/>
					<Button
						type="link"
						onClick={() => handleUp(id)}
						disabled={isDisabledUp}
						icon={<Icon component={ArrowUp} />}
					/>
					<Button
						type="link"
						onClick={() => handleDown(id)}
						disabled={isDisabledDown}
						icon={<Icon component={ArrowDown} />}
					/>
				</Col>
			</Row>
			<Row justify="end">
				<Col span={24} style={{ marginTop: 5 }}>
					<Divider style={{ margin: '0px', borderTopColor: '#26262666' }} />
				</Col>
			</Row>
		</React.Fragment>
	);
}

function Frequency({ id, default_value, name, disabled }: FrequencyProps) {
	return (
		<S.CenteredEditableInput>
			<S.ContainerInputNumber $borderRadius="8px">
				<Form.Item required={false} labelCol={{ span: 24 }} name={name} label={null}>
					<InputNumber
						size="large"
						placeholder="0"
						min={1}
						disabled={disabled}
						style={{ width: 70 }}
						defaultValue={default_value}
					/>
				</Form.Item>
			</S.ContainerInputNumber>
		</S.CenteredEditableInput>
	);
}
