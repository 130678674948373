import React, { useEffect } from 'react';
import { Col, Row, Form } from 'antd';

import { useGetRotationReportActivities } from '@/hooks/useGetRotationReportActivities';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { Text, Title } from '@/components/Typography';
import EmptyListIcon from '@/assets/icons/EmptyList';
import { ActivityListItem } from './ActivityListItem';
import { ContainerInfiniteScroll } from '../styles';

const { useFormInstance } = Form;

export function ActivityList() {
	const { basicParams, rotationReport } = useRotationReportContext();
	const { setFieldValue } = useFormInstance();
	const { data, isLoading } = useGetRotationReportActivities({
		...basicParams,
		id: rotationReport?.id
	});

	useEffect(() => {
		if (data) {
			setFieldValue('activities_item', data.length);
		}
	}, [data, setFieldValue]);

	if (isLoading) {
		return null;
	}

	function isDisabledUp(index: number) {
		const first_item_array = index === 0;
		return first_item_array;
	}

	function isDisabledDown(index: number) {
		const last_item_array = index + 1 === data?.length;
		return last_item_array;
	}

	function isDisabledEdit(activity_id: string) {
		const have_activity = rotationReport?.activities.some((s) => s.activity_id === activity_id);
		return !have_activity;
	}

	const fontSize = 17;

	return (
		<React.Fragment>
			<Row style={{ marginTop: '20px' }} justify="space-between">
				<Col xl={3} sm={0}>
					<Title level={5} style={{ textAlign: 'center', fontSize }}>
						Sequence
					</Title>
				</Col>
				<Col xl={8} sm={9}>
					<Title level={5} style={{ fontSize }}>
						Operation
					</Title>
				</Col>
				<Col span={3}>
					<Title level={5} style={{ textAlign: 'center', fontSize }}>
						Frequency
					</Title>
				</Col>
				<Col xl={6} sm={8}>
					<Title level={5} style={{ textAlign: 'center', fontSize }}>
						Operation time
					</Title>
				</Col>
				<Col span={3} />
			</Row>
			<ContainerInfiniteScroll id="activity-list">
				{!data?.length && (
					<div
						style={{
							padding: '2rem',
							textAlign: 'center'
						}}
					>
						<EmptyListIcon />
						<Text style={{ color: '#26262640' }}>No data</Text>
					</div>
				)}
				{data?.map((data, index) => (
					<ActivityListItem
						key={index}
						data={data}
						isDisabledEdit={isDisabledEdit(data.activity_id)}
						isDisabledDown={isDisabledDown(index)}
						isDisabledUp={isDisabledUp(index)}
					/>
				))}
			</ContainerInfiniteScroll>
		</React.Fragment>
	);
}
